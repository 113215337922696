@import '../styles/vars';
@import '../../common/styles/responsive';

.terms,
.privacy {
  .pageContainer();
  margin-top: -8vh;
  font-size: 20px;

  .dark {
    padding: 2vh 0 15vh 0;
  }

  h1,h2,h3 {
    margin-top: 1em;
    margin-bottom: .5em;
  }

  h1 {
    font-size: 34px;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 22px;
  }

  ul {
    list-style-type: disc;
    margin-left: 30px;
    margin-top: 20px;
  }

  li {
    margin: .5em 0;
  }

}
