@import './vars';

html {
  overflow-x: hidden;

  &.fixed {
    overflow-y: hidden;
  }
}

body {
  color: @pageColor;
  background-color: #0c0c0c;
  font: 28px/1.4 'Syne', sans-serif;
}

main {
  min-height: 80vh;
}

em {
  font-family: 'GT Sectra Display', serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  font-weight: normal;
}

h2 {
  font-size: 100px;

  @media only screen and (max-width: 1140px) {
    font-size: 80px;
  }

  @media only screen and (max-width: 1000px) {
    font-size: 60px;
  }

  @media only screen and (max-width: 850px) {
    font-size: 40px;
  }
}

h3 {
  font-size: 50px;

  @media only screen and (max-width: 850px) {
    font-size: 38px;
  }
}

sup {
  font-size: .5em;
  vertical-align: 1em;
}

hr {
  height: 3px;
  background-color: #303030;
}

marquee {
  position: relative;
  font-size: 200px;
  opacity: 0.5;
  user-select: none;

  &.white {
    color: #fff;
  }

  &.outline {
    color: rgba(0,0,0,0);
    -webkit-text-stroke: .25px #fff;
  }
}

blockquote {
  margin: 1em;
}

img {
  display: block;
  margin: 0 auto;
}
