@import '../../styles/vars';
@import '../../../common/styles/easing';
@import '../../../common/styles/responsive';

@duration: 500ms;

.header {
  height: 160px;
  margin-bottom: 19vh;

  &__logo {
    transition:
      transform 800ms @easeInOutCubic 1.2s,
      opacity 150ms linear;

    &.appear {
      @media @desktop {
        transform: translateY(calc(50vh - 110px)) scale(2);
      }
      @media @mobile {
        transform: translate(calc(50vw - 125px), calc(50vh - 110px)) scale(1.5);
      }
    }

    &.appear-active {
      transform: translateY(0) scale(1);
    }

    &.appear-done {
      transform: translateY(0) scale(1);
    }

    &--hidden {
      opacity: 0;
    }

    @media @mobile {
      width: 150px;
      height: auto;
    }
  }

  &__swipe-in {
    position: fixed;
    z-index: 1;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: @backgroundDark;
    transition: transform 800ms @easeInOutCubic 500ms;

    &.appear {
      transform: translateY(100%);
    }

    &.appear-active {
      transform: translateY(0);
    }

    &.appear-done {
      transform: translateY(0);
    }
  }

  &__overlay {
    position: fixed;
    z-index: @zHeaderOverlay;
    top: 0;
    left: 0;
    right: 0;
    padding: 80px;
    pointer-events: none;

    &-content {
      position: relative;
      display: flex;
      justify-content: center;

      @media @mobile {
        justify-content: flex-start;
      }
    }

    &-home-link {
      pointer-events: auto;

      @media @mobile {
        margin: 0 30px;
      }
    }

    @media @mobile {
      padding: 62px 25px;
    }
  }

  &__open-button {
    position: absolute;
    top: -20px;
    right: -20px;
    cursor: pointer;
    width: 110px;
    height: 60px;
    .button();

    &:before,
    &:after {
      content: '';
      position: absolute;
      right: 20px;
      height: 3px;
      background-color: #fff;
      transition: transform @duration @easeInOutCubic;
      transform-origin: right;
      transform: scaleX(0);
    }

    &:before {
      top: 20px;
      width: 65px;
    }

    &:after {
      top: 33px;
      width: 40px;
    }

    &.enter,
    &.appear {
      &:before,
      &:after {
        transform: scaleX(0);
      }
      &:before {
        transition-delay: 200ms;
      }
      &:after {
        transition-delay: 400ms;
      }
    }

    &.enter-active,
    &.appear-active {
      &:before,
      &:after {
        transform: scaleX(1);
      }
    }

    &.enter-done,
    &.appear-done {
      pointer-events: auto;
      &:before,
      &:after {
        transform: scaleX(1);
      }
    }
  }

  &__inline-nav {
    position: absolute;
    top: -10px;
    right: 0;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    font-weight: bold;
    opacity: 0;
    transform: translateY(-30%);
    transition:
      transform 250ms @easeOutCubic,
      opacity 250ms @easeOutCubic;
    pointer-events: none;

    &.enter,
    &.appear {
      opacity: 0;
      transform: translateY(-30%);
      transition-delay: 500ms;
    }

    &.enter-active,
    &.appear-active {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 500ms;
    }

    &.enter-done,
    &.appear-done {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 500ms;
      pointer-events: auto;
    }

    &.exit,
    &.exit-done,
    &.exit-active {
      transition-delay: 0;
      pointer-events: none;
    }

    &-link {
      font-size: 18px;
      transition:
        transform 250ms @easeOutCubic,
        opacity 250ms @easeOutCubic;

      &.appear {
        opacity: 0;
        transform: translateY(-100%);
      }

      &.appear-active {
        opacity: 1;
        transform: translateY(0);
      }

      &.appear-done {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &__nav {
    .navDesktopContainer() {
      @media @desktop {
        padding-left: 89px;
        padding-right: 80px;
      }
      @media @tablet {
        padding-left: 60px;
        padding-right: 30px;
      }
    }

    .navMobileContainer() {
      @media @mobile {
        padding-left: 25px;
        padding-right: 25px;
      }
    }

    &-panel {
      position: fixed;
      z-index: @zHeaderPanel;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      transform: translateX(100%);
      transition: transform @duration @easeInOutCubic;
      display: flex;
      align-items: center;

      &--active {
        transform: translateX(0);
      }

      &-dimmer {
        position: fixed;
        z-index: @zHeaderDimmer;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        transition: opacity @duration @easeInOutCubic;
        pointer-events: none;
        opacity: 0;
        cursor: pointer;

        &--active {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }

    &-close-button {
      color: #fff;
      cursor: pointer;
      .button();
    }

    &-desktop-link {
      width: 50vw;
      display: flex;
      align-items: center;
      justify-content: center;

      @media @mobile {
        display: none;
      }
    }

    &-desktop-logo {
      transform: translateX(-50%);
      opacity: 0;

      &.appear,
      &.appear-active,
      &.appear-done,
      &.enter,
      &.enter-active,
      &.enter-done {
        transition:
          transform 200ms @easeInOutCubic 500ms,
          opacity 200ms linear 500ms;
        transform: translateX(0);
        opacity: 1;
      }

      &.exit,
      &.exit-active,
      &.exit-done {
        transition:
          transform 200ms @easeInOutCubic,
          opacity 200ms linear;
        transform: translateX(-50%);
        opacity: 0;
      }
    }

    &-mobile-link {
      @media @desktop {
        visibility: hidden;
        pointer-events: none;
      }
    }

    &-content {
      height: 100%;
      color: @textGray;
      background-color: @backgroundDark;
      display: flex;
      flex-flow: column;

      @media @desktop {
        width: 50vw;
      }

      @media @mobile {
        width: 100vw;
      }
    }

    &-links {
      .navDesktopContainer();
      .navMobileContainer();
      display: flex;
      flex-flow: row;
      flex: auto;
    }

    &-link {
      font-size: 80px;
      transition: color 150ms linear;
      color: #fff;

      &--dim {
        color: #616161;
      }

      &:hover,
      &.active {
        color: #fff !important;
      }

      @media only screen and (max-height: 1250px) {
        font-size: 70px;
      }

      @media only screen and (max-height: 800px) {
        font-size: 45px;
      }

      @media only screen and (max-height: 600px) {
        font-size: 38px;
      }
    }

    &-top {
      .navDesktopContainer();
      .navMobileContainer();
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 59px;

      @media @mobile {
        margin-top: 53px;
      }
    }

    &-bottom {
      .navDesktopContainer();
      display: flex;
      align-items: stretch;

      @media @desktop {
        flex-flow: row;
        justify-content: space-between;
      }

      @media @mobile {
        flex-flow: column;
      }
    }

    &-links {
      display: flex;
      flex-flow: column;
      justify-content: center;
    }

    &-bottom {
      @media @desktop {
        margin-bottom: 80px;
      }
    }

    &-social-links {
      .navMobileContainer();

      @media @mobile {
        text-align: center;
        margin-bottom: 50px;
      }
    }

    &-social-link {
      margin: 0 10px;

      @media @mobile {
        margin: 0 30px;
      }
    }

    &-extra {
      .navMobileContainer();
      line-height: 1;

      @media @mobile {
        background-color: #0f0f0f;
        padding-top: 30px;
        padding-bottom: 30px;
        text-align: center;
      }

      @media @tiny {
        padding-top: 5px;
        padding-bottom: 10px;
      }
    }

    &-extra-link {
      font-size: 18px;
      margin: 0 0.5em;
      display: inline-block;

      @media @mobile {
        font-size: 22px;
      }

      @media @tiny {
        font-size: 18px;
      }
    }
  }
}
