.custom-cursor {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: none;
  pointer-events: none;
  will-change: transform;

  &__inner {
    width: 146px;
    height: 146px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 900;
    color: #8629df;
    background-color: #fff;

    &--down {
      transform: translate(-50%, -50%) rotate(-30deg);
      background-color: #8629df;
      color: #fff;
    }

  }

}
