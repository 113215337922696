@import '../../styles/vars';
@import '../../../common/styles/responsive';

.headquarters {
  position: relative;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media @desktop {
    height: 62.5vw;
    background-size: cover;
  }

  @media @mobile {
    padding-top: 80px;
    padding-bottom: 50px;
    background-size: auto 100%;
  }

  &__background {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  &__container {
    display: block;
    padding: 0 25px;
    max-width: 1140px;
    cursor: none;
  }

  h2 {
    font-size: 95px;

    @media only screen and (max-width: 1050px) {
      font-size: 75px;
    }

    @media only screen and (max-width: 850px) {
      font-size: 65px;
    }

    @media only screen and (max-width: 700px) {
      font-size: 60px;
    }

  }

  &__mail {
    margin-top: 50px;
    color: #616161;

    @media @desktop {
      text-align: right;
      font-size: 50px;
    }

    @media @mobile {
      font-size: 28px;
      max-width: 50vw;
    }

    a {
      color: #fff;
    }
  }

  &__mobile-cta {
    .mobileOnly();
    font-size: 14px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;

    &-icon {
      width: 76px;
      height: 76px;
      background-color: #fff;;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #8629df;
      margin-right: 10px;
    }

  }
}

