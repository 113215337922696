@import '../../../../common/styles/easing';
@import '../../../../common/styles/responsive';

.arrow-button {
  cursor: pointer;
  position: relative;
  .button();

  &--down {
    transform: rotate(180deg);
  }

  &--left {
    transform: rotate(-90deg);
  }

  &--right {
    transform: rotate(90deg);
  }

  &__stroke {
    stroke: #3f3f3f;
    stroke-width: 0.4px;
    fill: #161616 !important;

    &--dark {
      fill: #dedede !important;
    }

    @media @mobile {
      stroke-width: 0.8px;
    }
  }

  &__fill {
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
    color: #3f3f3f;
    clip-path: inset(0% 0% 100% 0%);
    transition: clip-path 500ms @easeInOutCubic;

    @media @desktop {
      .arrow-button:hover & {
        clip-path: inset(0% 0% 0% 0%);
      }
    }
  }
}
