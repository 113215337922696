@import '../../styles/vars';
@import '../../../common/styles/responsive';

.american-dream,
.orange-theory,
.litmus-health,
.e-flux,
.nuelife,
.housebar {
  position: relative;

  &__container {
    .pageContainer();
  }

  &__hero {
    .pageHero();
  }

  &__background {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;

    img {
      width: 100%;
      margin: 0;
    }
  }

  &__inset {
    @media @desktop {
      display: flex;

      :first-child {
        flex: 0 0 6em;
        margin-right: 1em;
      }
    }
    @media @mobile {
      :first-child {
        margin-bottom: 1em;
      }
    }
  }

  &__feature-list {
    position: relative;
    z-index: 1;

    @media @desktop {
      padding-left: 161px;
    }

    li {
      padding-top: 60px;
      padding-bottom: 60px;

      @media @desktop {
        &:hover {
          padding-left: 60px;
        }
      }

      @media @mobile {
        padding-top: 20px;
        padding-bottom: 20px;
        margin-left: 25px;
        padding-right: 25px;
        &:hover {
          padding-left: 25px;
        }
      }

      &:hover {
        background-color: #000;
        color: #fff;
        box-shadow: 8px 39px 49px 0 rgba(0, 0, 0, 0.28);
        border: none !important;
      }

      em {
        opacity: 0.2;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #fff;
      }
    }
  }

  &__stats {
    display: flex;
    overflow-x: auto;
    .button();

    &::-webkit-scrollbar {
      display: none;
    }

    @media @desktop {
      max-width: 1040px;
      margin: 0 auto;
    }

    @media @mobile {
      padding: 0 25px;
    }
  }

  &__stat {
    @media @desktop {
      margin-right: 110px;
    }

    @media @mobile {
      margin-right: 50px;
    }

    &-title {
      font-size: 100px;
      font-weight: 900;
    }
  }

  &__left-offset {
    @media @desktop {
      padding-left: 8vw;
    }
  }

  &__overview,
  &__outcomes {
    &-container {
      position: relative;
      z-index: 1;
    }

    &-quote {
      font-weight: bold;
      font-size: 50px;
      margin: 0;

      &:before {
        content: '“';
        display: block;
        color: #8629df;
        line-height: 1;
      }

      @media @mobile {
        font-size: 40px;
      }

      &-top {
        color: #8629df;
      }

      &-title {
        font-weight: bold;
        font-size: 28px;
      }
    }
  }
}
