@import '../../styles/vars';
@import '../../../common/styles/easing';
@import '../../../common/styles/responsive';

.case-study {

  @media @desktop {
    margin: 0 6vw;
  }

  &__link {
    display: block;
    user-select: none;
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;

    &-container {
      position: relative;
      overflow: hidden;
      max-width: @containerMedium;
      margin: 0 auto;
    }

    @media @desktop {
      transition: transform 400ms @easeInOutCubic;

      .case-study__link:hover & {
        transform: scale(1.2) rotate(-5deg);
      }
    }
  }

  &__header {
    margin-top: 1em;
    display: flex;
    flex-direction: column;

    @media @desktop {
      margin-left: 10.5vw;
      flex-direction: row;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: bold;

    @media @mobile {
      font-size: 14px;
    }
  }

  &__subtitle {
    font-size: 18px;
    font-weight: bold;
    color: #616161;

    @media @desktop {
      margin-left: 2em;
    }

    @media @mobile {
      font-size: 14px;
    }
  }

  &__tagline {
    font-size: 50px;
    font-weight: normal;
    margin-top: 1em;

    @media @desktop {
      margin-left: 3em;
    }

    @media @tablet {
      font-size: 38px;
    }

    @media @mobile {
      font-size: 38px;
    }
  }
}
