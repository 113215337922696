@import '../../styles/vars';
@import '../../../common/styles/easing';
@import '../../../common/styles/responsive';

.grow {
  &__container {
    .pageContainer();
  }

  &__hero {
    .pageHero();
  }

  &__small {
    font-size: 0.85em;
  }

  &__images {
    display: flex;
    flex-flow: column;
    gap: 50px;

    img {
      display: block;
      width: 100%;
    }
  }

  &__link {
    &-text {
      display: flex;
      align-items: center;
      justify-content: center;

      color: #8629df;
    }

    &-arrow {
      width: auto;
      height: 30px;
      margin: 0 20px 0 0;
    }
  }

  &__map {
    display: block;
  }

  &__mail-link {
    color: #8629df;
  }

  &__cards {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    margin: 0 30px;
  }

  &__card {
    color: #000;
    background: #ffffff;
    padding: 30px;
    font-size: 0.9em;

    &-number {
      font-weight: 900;
      font-size: 50px;
      color: #dedede;
      line-height: 1;
      margin: -20px 20px 20px -10px;
    }

    h3 {
      font-size: 36px;
      margin-bottom: 0.5em;
    }
  }

  &__case-study {
    &-image {
      display: block;
      width: 100%;
      height: 50vh;
      object-fit: cover;
      transition: transform 400ms @easeInOutCubic;

      &:hover {
        transform: scale(1.2) rotate(-5deg);
      }

      &-container {
        overflow: hidden;
      }
    }
  }

  &__taglines {
    display: flex;
    gap: 30px;
    font-size: 18px;
    font-weight: 700;
    max-width: 600px;
    margin: 0 auto;

    @media @mobile {
      flex-flow: column;
      gap: 10px;
    }
  }

  &__tagline {
    &--sub {
      color: #616161;
    }
  }

  &__clocks {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
    .button();

    @media @mobile {
      overflow-x: auto;
      overflow-y: hidden;
      justify-content: flex-start;
      padding-left: 40px;
      padding-right: 40px;
    }

    .clock {
      flex: none;
    }

    .clock:nth-child(2) {
      margin: 0 55px;

      @media @tablet {
        margin: 0 38px;
      }

      @media @tiny {
        margin: 0 38px;
      }
    }
  }

  &__dna {
    &-image {
      width: 100%;
    }

    &-stats {
      display: grid;
      gap: 50px;
      grid-template-columns: repeat(4, 1fr);

      @media (max-width: 976px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &-stat {
      font-size: 18px;
      text-align: center;

      &-number {
        font-size: 80px;
        background: linear-gradient(to bottom right, #8629df, #ab52a0);
        color: transparent;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        line-height: 1;
        margin-bottom: 20px;
      }
    }
  }
}
