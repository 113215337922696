@import '../../../../common/styles/easing';
@import '../../../../common/styles/responsive';
@import '../card-styles';

.stack {
  .cardStyles();
  position: relative;

  &__container {
    height: 604px;

    @media @mobile {
      height: 450px;
    }

    @media @tiny {
      height: 350px;
    }
  }

  &__item {
    cursor: none;
    user-select: none;
    transition: transform 200ms @easeInOutCubic, opacity 200ms @easeInOutCubic, transform-origin 200ms @easeInOutCubic;

    &--top {
      position: relative;
      transform-origin: 50% 180%;
    }

    &--gone,
    &--bottom {
      position: absolute;
      top: 0;
      left: 0;
    }

    &--gone {
      transform-origin: 50% 180%;
      opacity: 0;
      pointer-events: none;
    }

    &--bottom {
      transform-origin: 50% 130%;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #292929;
        opacity: 1;
        transition: opacity 200ms linear;

        .stack--inverted & {
          background-color: #b7b7b7;
        }
      }
    }

    &--ready {
      &:after {
        opacity: 0;
      }
    }

  }

  &__button-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    &--hidden {
      pointer-events: none;
      visibility: hidden;
    }
  }

  &__button {
    width: 125px;
    height: 125px;
    background-color: #fff;
    color: #8629df;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 900;
    transition: transform 200ms @easeInOutCubic;
    .button();

    &--down {
      background-color: #8629df;
      color: #fff;
    }

    @media @tiny {
      width: 100px;
      height: 100px;
      font-size: 14px;
    }

  }

  &__swipe-bg {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 40px;
    width: calc(100% - 80px);
    border-bottom: 3px dotted #353535;
    margin-top: -1.5px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: -11px;
      width: 20px;
      height: 20px;
      border: 3px solid #353535;
      border-radius: 50%;
    }

    &:before {
      left: -30px;
    }

    &:after {
      right: -30px;
    }
  }

}
