@import '../../styles/vars';
@import '../../../common/styles/responsive';

.services {

  &__container {
    .pageContainer();
  }

  &__hero {
    .pageHero();
  }

  &__tech {
    &-title {
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: .5em;
    }
    &-subtitle {
      color: #616161;
      font-size: 14px;
      font-weight: bold;
    }
  }

  &__desktop-cards {
    margin-bottom: -50vh;
    position: relative;
    z-index: 1;
  }

  &__mobile-cards {
    margin-bottom: -50vh;
    position: relative;
    z-index: 1;
  }

  &__card {
    background: #fff;
    color: #000;
    width: 470px;
    height: 604px;
    margin: 0 10px;
    flex: none;
    padding: 65px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    max-width: 95vw;

    @media only screen and (max-height: 800px) {
      width: 364px;
      height: 468px;
      padding: 30px;
    }

    &-number {
      font-weight: 900;
      font-size: 100px;
      color: #dedede;
      line-height: 1;

      @media only screen and (max-height: 800px) {
        font-size: 50px;
      }
    }

    @media @mobile {
      margin: 0 auto;
    }

  }

  &__partners {
    color: #161616;
    background-color: #dedede;
    padding-top: 50vh;

    .container {
      position: relative;
      z-index: 1;
    }

    &-title {
      display: flex;
      align-items: center;
    }

    &-logo {
      margin-right: 72px;

      @media @mobile {
        margin-right: 19px;
      }
    }

    &-name {
      max-width: 330px;
    }

    &-body {
      display: flex;
      align-items: flex-start;

      @media @mobile {
        flex-direction: column;
      }
    }

    &-two {
      @media @desktop {
        margin-top: 90px;
        margin-left: 97px;
      }

      @media @mobile {
        margin-top: 1em;
      }
    }
  }

  &__brands {
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    padding-bottom: 100px;

    @media @desktop {
      flex-direction: column-reverse;
    }

    &-logos {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 200ms linear;

      @media @desktop {
        padding-left: 40vw;
        margin-top: 15vh;
        margin-bottom: 10vh;
      }

      @media @mobile {
        flex-direction: column;
      }

      img {
        max-height: 50px;
        margin: 0 40px;

        @media @mobile {
          margin: 27px 0;
        }
      }
    }

    &-title {

      h3 {
        font-size: 50px;

        @media @mobile {
          font-size: 28px;
        }
      }

      hr {
        margin-top: 50px;

        @media @mobile {
          margin-top: 30px;
        }
      }
    }
  }
}

