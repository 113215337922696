@font-face {
  font-family: 'Syne';
  src: url('../assets/fonts/syne/woff2/Syne-Regular.woff2') format('woff2'),
       url('../assets/fonts/syne/woff/Syne-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Syne';
  src: url('../assets/fonts/syne/woff2/Syne-Bold.woff2') format('woff2'),
       url('../assets/fonts/syne/woff/Syne-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Syne';
  src: url('../assets/fonts/syne/woff2/Syne-Extra.woff2') format('woff2'),
       url('../assets/fonts/syne/woff/Syne-Extra.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'GT Sectra Display';
  src: url('../assets/fonts/sectra/woff2/GT-Sectra-Display-Light-Italic.woff2') format('woff2'),
       url('../assets/fonts/sectra/woff/GT-Sectra-Display-Light-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
