@import '../../styles/vars';
@import '../../../common/styles/responsive';

.scene {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  &--fixed {
    @media @desktop {
      position: fixed;
      z-index: @zHeaderScene;
    }
  }
}

