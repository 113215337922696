@import '../../styles/vars';
@import '../../../common/styles/easing';
@import '../../../common/styles/responsive';

.case-studies {

  &__container {
    .pageContainer();
  }

  &__hero {
    position: relative;
    top: -73px;

    @media only screen and (max-width: 1140px) {
      top: -58px;
    }

    @media only screen and (max-width: 1000px) {
      top: -43px;
    }

    @media only screen and (max-width: 850px) {
      top: -30px;
    }
  }

  &__about {
    cursor: none;

    &-link {
      display: block;
    }
  }

}
