@import '../../../common/styles/easing';
@import '../../../common/styles/responsive';

.clock {
  position: relative;

  &__container {
    position: relative;
    z-index: 1;
    background: #161616;
    box-shadow: -10px 30px 40px 0 rgba(0,0,0,0.28);
    width: 320px;
    height: 320px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;

    @media @tablet {
      width: 260px;
      height: 260px;
    }

    @media @mobile {
      width: 260px;
      height: 260px;
    }

    @media @desktop {
      opacity: 0;
      transition: opacity 100ms linear;

      &:hover {
        opacity: 1;
      }

    }

    @media @mobile {
      width: 60vw;
      height: 60vw;
    }
  }

  @media @desktop {

    &:after {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: calc(100% - 6px);
      height: calc(100% - 6px);
      border: 3px solid #202020;
      border-radius: 50%;
      pointer-events: none;
    }

  }

  &__hand {
    position: absolute;
    top: 0;
    left: 0;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    user-select: none;
    transition: transform 200ms @easeOutBack;

    &:after {
      content: '';
      position: absolute;
      top: 10%;
      left: 50%;
      width: 14px;
      height: 14px;
      margin: -7px 0 0 -7px;
      border-radius: 50%;
    }

    &--hour {
      z-index: 3;

      &:after {
        background-color: #8629df;
      }
    }

    &--minute {
      z-index: 2;

      &:after {
        background-color: #616161;
      }
    }

    &--second {
      z-index: 1;

      &:after {
        background-color: #212121;
      }
    }

  }

  &__area {
    text-align: center;
    line-height: 1.4;

    &-city {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
    }

    &-country {
      color: #616161;
      font-size: 20px;
    }
  }
}
