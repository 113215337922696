.caption {

  &__word {
    display: inline-block;
    margin-right: .25em;
    color: #fff;

    &--active {
      color: #161616;
    }
  }
}
