@import '../../../../common/styles/easing';
@import '../../../../common/styles/responsive';

.flip-card {
  width: 470px;
  height: 604px;
  perspective: 3000px;

  @media @mobile {
    width: 350px;
    height: 450px;
  }

  @media @tiny {
    width: 290px;
    height: 350px;
  }

  &__flipper {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    animation: flip-out 100ms @easeInOutCubic forwards;
    transform-origin: 50% 60%;

    &.enter,
    &.enter-active,
    &.enter-done {
      animation: flip-in 600ms @easeInOutCubic forwards;
    }

    &.exit,
    &.exit-active,
    &.exit-done {
      animation: flip-out 600ms @easeInOutCubic forwards;
    }

  }

  &__front,
  &__back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }

  &__front {
    z-index: 2;

    &-number {
      position: absolute;
      top: 40px;
      left: 20px;
      font-size: 18px;
      font-weight: bold;
      transform: rotate(-90deg);
      color: #616161;
    }

    &-text {
      position: absolute;
      width: 64%;
      right: 30px;
      bottom: 60px;
      font-size: 50px;
      color: #fff;
      text-align: right;
      line-height: 1.2;

      @media @mobile {
        font-size: 38px;
      }
    }
  }

  &__back {
    z-index: 1;
    transform: rotateY(180deg);

    &-number {
      position: absolute;
      top: 40px;
      left: 20px;
      font-size: 18px;
      font-weight: bold;
      transform: rotate(-90deg);
      color: #616161;

      @media @tiny {
        top: 25px;
        left: 15px;
      }
    }

    &-text {
      position: absolute;
      top: 87px;
      left: 81px;
      bottom: 87px;
      right: 81px;
      font-size: 28px;
      color: #161616;
      line-height: 1.4;

      @media @mobile {
        left: 35px;
        right: 35px;
      }

      @media @mobile {
        left: 15px;
        right: 15px;
        font-size: 22px;
      }
    }
  }

}

@keyframes flip-in {
  0% {
    transform: scale(1) rotateY(0);
  }
  50% {
    transform: scale(1.2) rotateY(90deg);
  }
  100% {
    transform: scale(1) rotateY(180deg);
  }
}

@keyframes flip-out {
  0% {
    transform: scale(1) rotateY(180deg);
  }
  50% {
    transform: scale(1.2) rotateY(90deg);
  }
  100% {
    transform: scale(1) rotateY(0);
  }
}
