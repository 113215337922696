@pageColor: #fff;
@backgroundDark: #161616;
@backgroundLight: #dedede;

@textGray: #616161;

@containerLarge: 1280px;
@containerMedium: 1040px;
@containerSmall: 950px;

@spacing-xs: 16px;
@spacing-s: 32px;
@spacing-m: 64px;
@spacing-l: 128px;
@spacing-xl: 256px;

@zFooter: 1;
@zContent: 2;
@zHeaderOverlay: 3;
@zHeaderScene: 4;
@zHeaderDimmer: 5;
@zHeaderPanel: 6;

.pageContainer() {
  position: relative;
  z-index: @zContent;
}

.pageHero() {
  position: relative;
  z-index: @zContent;
  margin-left: clamp(40px, 12vw, 200px);

  h2 {
    font-size: clamp(3vw, 100px, 7vw);
  }

  h3 {
    font-size: clamp(3vw, 50px, 6vw);
  }
}
