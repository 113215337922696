@import '../../styles/vars';
@import '../../../common/styles/easing';
@import '../../../common/styles/responsive';

.scroll-arrow {
  position: fixed;
  z-index: 0;
  bottom: 0;
  left: 0;
  width: 40vw;
  height: 60vh;
  min-width: 220px;
  padding: 240px 0 80px 8vw;
  color: #3f3f3f;
  transition:
    opacity 400ms @easeInOutCubic,
    width 200ms ease-out;
  background-color: @backgroundDark;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  &.appear {
    transform: translateX(-100%);
    opacity: 0;
  }

  &.appear-active {
    transform: translateX(0);
    opacity: 1;
  }

  &.appear-done {
    transform: translateX(0);
    opacity: 1;
  }

  &__container {
    opacity: 1;
    transition: opacity 150ms linear;
    width: ~'min(145px, 18vw)';
    flex: none;

    .ui.icon {
      width: 100% !important;
      height: auto !important;
    }

    &--hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__top {
    position: absolute;
    top: 80px;
    left: 80px;
    font-size: 18px;
    margin-right: 120px;

    @media @mobile {
      display: none;
    }
  }

  &__title {
    font-weight: bold;
    color: #fff;
  }

  &__subtitle {
    color: #616161;
  }
}
