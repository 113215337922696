@import '../../styles/vars';
@import '../../../common/styles/responsive';

.contact {
  &__container {
    .pageContainer();
  }

  .headquarters {
    position: relative;
    top: -30vh;
  }
}
