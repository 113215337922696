@import '../../styles/vars';
@import '../../../common/styles/easing';
@import '../../../common/styles/responsive';

.about {

  &__container {
    .pageContainer();
  }

  &__hero {
    .pageHero();
  }

  &__background {

    &-1 {
      @media @desktop {
        display: flex;
        align-items: flex-end;

        h3 {
          padding-right: 1em;
        }
      }

    }

    &-2 {
      @media @desktop {
        display: flex;
        align-items: flex-end;

        > div {
          flex-basis: 50%;
        }

        > div:first-child {
          padding-right: 1em;
        }

        > div:last-child {
          padding-left: 3em;
        }
      }

    }

  }

  &__divider {
    .desktopOnly();
    height: 3px;
    background-color: #fff;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-right: -30vw;
  }

  &__team {
    &-list {
      @media @mobile {
        display: flex;
        transition: transform 400ms @easeInOutCubic;
      }
    }

    &-title {
      font-size: 14px;
      font-weight: bold;
      color: #616161;
    }

    &-header {
      @media @desktop {
        padding-top: 150px;
      }

      @media @mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 50px;
        padding-bottom: 20px;
      }
    }

    &-counter {
      .mobileOnly();
      width: 76px;
      text-align: center;
      color: #616161;

      &-top {
        font-size: 30px
      }

      &-bottom {
        font-size: 30px;
      }

      &-divider {
        height: 3px;
        background-color: #fff;
      }

      &-bottom {
      }
    }
  }


  &__label {
    font-size: 100px;
    margin: 0 0.2em;
    white-space: nowrap;
    color: #3f3f3f;

    @media @mobile {
      font-size: 60px;
    }

    &--white {
      color: #fff;
    }

    &--strike {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: .8em;
        width: 100%;
        height: 4px;
        background-color: #3f3f3f;

        .about__label--white& {
          background-color: #fff;
        }
      }
    }
  }

  &__quote {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    h2 {
      font-size: 80px;

      @media @mobile {
        font-size: 60px;
      }
    }

    &-background {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: auto;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &-container {
      position: relative;
      display: flex;
      align-items: flex-end;

      @media @mobile {
        flex-direction: column-reverse;
        align-items: flex-start;
      }

      > div:first-child {
        flex: 0 0 30%;
        padding-right: 100px;
      }

      &:after {
        content: '“';
        position: absolute;
        top: -0.2em;
        left: 0.7em;
        font-size: 150px;
        color: #161616;
        line-height: 1;

        @media @mobile {
          position: relative;
          top: 0;
          left: 0;
          font-size: 100px;
        }
      }
    }

    @media @desktop {
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 10vh;
        left: 30vw;
        width: 470px;
        height: 470px;
        border-radius: 50%;
      }

      &:before {
        z-index: 2;
        background-color: #fff;
        mix-blend-mode: difference;
      }

      &:after {
        z-index: 3;
        background-color: #8629df;
        mix-blend-mode: lighten;
      }
    }

    &-text {
      margin: 0;
    }

    &-roi {
      margin-top: 100px;
    }

    &-services-link {
      display: flex;
      align-items: center;
      margin-top: 20px;
    }

    &-services-icon {
      width: 76px;
      height: 76px;
      margin-right: 10px;
      background-color: #fff;
      border-radius: 50%;
      color: #8629df;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &-services-text {
      font-size: 14px;
      font-weight: bold;
    }

  }


}
