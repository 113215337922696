body {
  margin: 0;
  height: auto;
  overflow-x: hidden;
}
* {
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
p {
  margin: 0;
  min-height: 1em;
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}
a > img,
a > svg {
  display: block;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
dd {
  margin: 0;
}
video {
  width: 100%;
}
select,
textarea {
  font-family: inherit;
  font-size: inherit;
}
img {
  display: block;
  max-width: 100%;
}
hr {
  border: none;
}
html {
  overflow-x: hidden;
}
html.fixed {
  overflow-y: hidden;
}
body {
  color: #fff;
  background-color: #0c0c0c;
  font: 28px/1.4 'Syne', sans-serif;
}
main {
  min-height: 80vh;
}
em {
  font-family: 'GT Sectra Display', serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  font-weight: normal;
}
h2 {
  font-size: 100px;
}
@media only screen and (max-width: 1140px) {
  h2 {
    font-size: 80px;
  }
}
@media only screen and (max-width: 1000px) {
  h2 {
    font-size: 60px;
  }
}
@media only screen and (max-width: 850px) {
  h2 {
    font-size: 40px;
  }
}
h3 {
  font-size: 50px;
}
@media only screen and (max-width: 850px) {
  h3 {
    font-size: 38px;
  }
}
sup {
  font-size: 0.5em;
  vertical-align: 1em;
}
hr {
  height: 3px;
  background-color: #303030;
}
marquee {
  position: relative;
  font-size: 200px;
  opacity: 0.5;
  user-select: none;
}
marquee.white {
  color: #fff;
}
marquee.outline {
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 0.25px #fff;
}
blockquote {
  margin: 1em;
}
img {
  display: block;
  margin: 0 auto;
}
@font-face {
  font-family: 'Syne';
  src: url('./assets/fonts/syne/woff2/Syne-Regular.woff2') format('woff2'), url('./assets/fonts/syne/woff/Syne-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Syne';
  src: url('./assets/fonts/syne/woff2/Syne-Bold.woff2') format('woff2'), url('./assets/fonts/syne/woff/Syne-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Syne';
  src: url('./assets/fonts/syne/woff2/Syne-Extra.woff2') format('woff2'), url('./assets/fonts/syne/woff/Syne-Extra.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'GT Sectra Display';
  src: url('./assets/fonts/sectra/woff2/GT-Sectra-Display-Light-Italic.woff2') format('woff2'), url('./assets/fonts/sectra/woff/GT-Sectra-Display-Light-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
.light {
  background-color: #dedede;
  color: #000;
}
.dark {
  background-color: #161616;
  color: #fff;
  -webkit-font-smoothing: antialiased;
}
.faded {
  opacity: 0.5;
}
.indent {
  text-indent: 1em;
}
@media only screen and (max-width: 767px) {
  .desktop-only {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) {
  .mobile-only {
    display: none !important;
  }
}
.ui.container {
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
}
.ui.container.large {
  max-width: 1280px;
}
.ui.container.small {
  max-width: 950px;
}
.ui.container.center {
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 1127px) {
  .ui.container {
    padding: 0 25px;
  }
}
@media only screen and (max-width: 767px) {
  .ui.container {
    padding: 0 25px;
  }
}
.ui.container.small {
  max-width: 950px;
}
