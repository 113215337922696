@import '../../styles/vars';
@import '../../../common/styles/easing';
@import '../../../common/styles/responsive';

.home {

  &__container {
    .pageContainer();
  }

  &__hero {
    .pageHero();
  }

  &__profile {
    position: relative;
    background-color: @backgroundLight;

    &-image {
      position: relative;
      width: 307px;
      height: 394px;

      img {
        display: block;
        width: 100%;
      }
    }

    &-title {
      text-align: right;
      font-size: 28px;
      font-weight: bold;
      line-height: 1;
      margin-top: 1em;

      @media @mobile {
        text-align: left;
        margin-left: 1em;
      }
    }

    &-body {
      text-align: right;
      font-size: 28px;

      @media @mobile {
        text-align: left;
        margin-left: 1em;
      }
    }
  }

  &__quote {
    margin-top: 0;

    @media @desktop {
      padding-left: 100px;
    }

    @media @tablet {
      padding-left: 50px;
    }

    &-prefix {
      font-size: 100px;
      display: block;
      line-height: .65;
    }

    &-cursor {
      cursor: none;
      user-select: none;
    }

    &-text {
      color: #000;
      font-size: 50px;
      margin: 0;

      @media @mobile {
        font-size: 38px;
      }

      @media @tiny {
        font-size: 28px;
      }

    }

  }

  &__play,
  &__pause {
    position: absolute;
    right: -20px;
    bottom: 20px;
    cursor: pointer;

    &-text {
      font-weight: bold;
      color: #fff;
      font-size: 18px;

      @media @mobile {
        font-size: 14px;
      }
    }

    &-button {
      display: inline-block;
      vertical-align: middle;
      width: 65px;
      height: 65px;
      border-radius: 50%;
      background-color: #fff;
      color: #8629df;
      margin-left: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__case-studies {
    &-heading {
      @media @desktop {
        margin-left: -0.43em;
      }
      @media @tablet {
        margin-left: .5em;
      }
    }
  }

  &__case-study {

    &-image {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-title {
      font-size: 18px;
      font-weight: bold;
    }

    &-subtitle {
      font-size: 18px;
      font-weight: bold;
      color: #616161;
      margin-left: 2em;
    }

    &-tagline {
      font-size: 50px;
      font-weight: normal;
    }
  }

  &__clocks {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
    .button();

    @media @mobile {
      overflow-x: auto;
      overflow-y: hidden;
      justify-content: flex-start;
      padding-left: 40px;
      padding-right: 40px;
    }

    .clock {
      flex: none;
    }

    .clock:nth-child(2) {
      margin: 0 55px;

      @media @tablet {
        margin: 0 38px;
      }

      @media @tiny {
        margin: 0 38px;
      }

    }
  }

  &__mobile-cta {
    .mobileOnly();
    font-size: 14px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;

    &-icon {
      width: 76px;
      height: 76px;
      background-color: #fff;;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #8629df;
      margin-right: 10px;
    }

  }
}
