@import '../../../../common/styles/responsive';

.marquee {
  color: #fff;
  font-size: 200px;
  transition: opacity 200ms linear;
  line-height: 1;
  margin-top: 20vh;
  opacity: 1;

  img {
    display: inline-flex;
    margin: 0 50px;
    transform: translateX(-100vw);
  }

  &--sticky {
    position: sticky;
    top: 0;
  }

  &--pull {
    @media @desktop {
      margin-bottom: -1.5em;
      margin-top: 1em;
    }
  }

  &--hidden {
    opacity: 0;
  }

  &--stroke {
    -webkit-text-stroke: 0.25px white;
    color: rgba(0, 0, 0, 0);
  }

  @media @mobile {
    font-size: 100px;
  }
}
