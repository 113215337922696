@import '../../common/styles/responsive';
@import './vars';

.ui.container {
  max-width: @containerMedium;

  &.large {
    max-width: @containerLarge;
  }

  &.small {
    max-width: @containerSmall;
  }

  &.center {
    text-align: center;
  }

  margin-left: auto;
  margin-right: auto;

  @media @tablet {
    padding: 0 25px;
  }

  @media @mobile {
    padding: 0 25px;
  }

  &.small {
    max-width: @containerSmall;
  }

}
