@import '../../../../common/styles/easing';
@import '../../../../common/styles/responsive';

.swing {
  transition: opacity 500ms @easeOutCubic, transform 500ms @easeOutCubic;
  transform-origin: -80vh -10vh;
  transform: rotate(5deg);
  opacity: 0;

  &--disabled {
    transition: none;
    transform: none;
    opacity: 1;
  }

  &.enter,
  &.appear {
    opacity: 0;
    transform: rotate(5deg);
  }

  &.enter-active,
  &.appear-active {
    opacity: 1;
    transform: rotate(0);
  }

  &.enter-done,
  &.appear-done {
    opacity: 1;
    transform: rotate(0);
  }
}
