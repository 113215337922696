.debug-panel {
  position: fixed;
  z-index: 100000;
  top: 15px;
  left: 15px;
  font-size: 16px;
  font-family: system-ui;
  font-weight: bold;
  user-select: none;
  background: rgba(0,0,0,.75);
  padding: 12px 30px 12px 15px;
  border-radius: 5px;
  box-shadow: 0px 2px 3px rgba(0,0,0,.3);

  &__close {
    position: absolute;
    top: 0px;
    right: 10px;
    font-size: 24px;
    font-weight: bold;
    opacity: .2;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  &__fps {
    text-align: center;
    margin-top: .5em;
  }

  fieldset {
    border: none;
    margin: 0;
    padding: 0;
  }
}

html.no-animations {
  *,
  *:before,
  *:after {
    transition: none !important;
    animation: none !important;
  }
}
