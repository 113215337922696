body {
  margin: 0;
  // Keep height auto to allow FullHeight component
  // to calculate offsets correctly;
  height: auto;
  // Semantic grids have negative margins so this is
  // the easiest way of handling horizontal overflow.
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6 {
  margin: 0;
}

p {
  margin: 0;
  min-height: 1em;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

a > img,
a > svg {
  display: block;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

dd {
  margin: 0;
}

video {
  width: 100%;
}

select,
textarea {
  font-family: inherit;
  font-size: inherit;
}

img {
  display: block;
  max-width: 100%;
}

hr {
  border: none;
}
