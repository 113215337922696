@import '../../../common/styles/responsive';

.card-section {
  position: relative;

  &__background {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__marquee {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 0;
    margin: 0;
    transform: translateY(-50%);

    @media @mobile {
      top: 0;
    }
  }

}
