@import '../../styles/vars';
@import '../../../common/styles/responsive';

main {
  margin-bottom: 390px;

  @media @tablet {
    margin-bottom: 355px;
  }

  @media @mobile {
    margin-bottom: 545px;
  }
}

.footer {
  position: fixed;
  z-index: @zFooter;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0f0f0f;
  color: #3f3f3f;
  display: none;

  @media @desktop {
    .arrow-button {
      width: 165px;
    }

    .ui.icon {
      width: 100% !important;
      height: 100% !important;
    }
  }

  &--active {
    display: block;
  }

  &__group {
    border-top: 2px solid #3f3f3f;
    padding: 40px 0;
  }

  &__mail {
    font-size: 50px;
    color: #fff;

    @media @tablet {
      font-size: 38px;
    }

    @media @mobile {
      font-size: 38px;
    }
  }

  &__location {
    font-size: 50px;

    @media @tablet {
      font-size: 38px;
    }

    @media @mobile {
      font-size: 25px;
    }
  }

  &__left {
    @media @mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-desktop {
      .desktopOnly();
    }

    &-mobile {
      .mobileOnly();
      font-size: 14px;
      display: flex;
      align-items: flex-end;

      &-icon {
        color: #fff;
        margin-left: 14px;
      }
    }
  }
}
