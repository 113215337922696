.equalizer {
  display: flex;
  align-items: center;
  justify-content: center;

  &__bar {
    width: 2px;
    background-color: #8629df;
    animation: linear infinite running equalizer-pulse;

    &:not(:last-child) {
      margin-right: 2px;
    }

    &--1 {
      height: 8px;
      animation-duration: 300ms;
    }

    &--2 {
      height: 17px;
      animation-duration: 350ms;
    }

    &--3 {
      height: 13px;
      animation-duration: 320ms;
    }

    &--4 {
      height: 10px;
      animation-duration: 380ms;
    }

    &--5 {
      height: 17px;
      animation-duration: 300ms;
    }
  }
}

@keyframes equalizer-pulse {
  0% {
    transform: scaleY(1.2);
  }
  100% {
    transform: scaleY(0.5);
  }
}
