@import '../../../common/styles/responsive';

.scroll-container {

  &__items {
    display: flex;
    overflow: auto;
    padding: 40px 12.7vw;
    user-select: none;

    &--no-cursor {
      cursor: none;
    }

    @scrollbarWidth: 3px;
    @scrollbarMargin: 6px;

    &::-webkit-scrollbar {
      display: none;
    }

  }

  &__title {
    color: #fff;
    font-size: 18px;
    font-weight: bold;

    &--large {
      font-size: 50px;

      @media @mobile {
        font-size: 28px;
      }
    }

    &--dark {
      color: #161616;
    }
  }

  &__subtitle {
    color: #616161;
    font-size: 18px;
    font-weight: bold;
  }

  &__arrow-group {

    &--right {
      padding-left: 17px;

      @media @mobile {
        padding-left: 10px;
      }
    }
  }

}
