@import '../../../../common/styles/easing';
@import '../../../../common/styles/responsive';

.scroll-fade {
  transition: opacity 100ms linear;
  opacity: 0.1;

  &--disabled {
    transition: none !important;
    opacity: 1 !important;
  }

  &--entered {
    opacity: 1;
  }
}
