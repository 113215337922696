@import '../../../common/styles/responsive';
@import '../../styles/vars';

.spacer(@height) {

  &:not(.spacer--pull) {
    height: @height;

    @media @mobile {
      height: max(round(@height * .75), @spacing-xs);
    }
  }

  &.spacer--pull {
    margin-bottom: -@height;
  }

}

.spacer {
  &--xs { .spacer(@spacing-xs) }
  &--s  { .spacer(@spacing-s)  }
  &--m  { .spacer(@spacing-m)  }
  &--l  { .spacer(@spacing-l)  }
  &--xl { .spacer(@spacing-xl) }

  &--pull {
    // Prevents parent margin collapse
    display: flex;
  }
}
