@import '../../../common/styles/responsive';

.team-member {
  position: relative;

  @media @desktop {
    min-height: 2.5em;
  }

  @media @mobile {
    flex: 0 0 80vw;
  }


  &__top {

    @media @desktop {
      display: flex;
      justify-content: space-between;
      margin-right: 25px;
    }
  }

  &__image {
    .mobileOnly();
    position: relative;
    left: -25px;
  }

  &__name {
    font-weight: bold;
    @media @desktop {
      display: inline-block;
      font-size: 40px;
    }
    @media @mobile {
      margin-top: 1em;
      font-size: 28px;
    }
  }

  &__title {
    @media @desktop {
      display: inline-block;
      font-size: 40px;
      margin-left: .5em;
    }
    @media @mobile {
      font-size: 28px;
    }
  }

  &__body {
    @media @desktop {
      margin-top: 40px;
      margin-left: 80px;
    }
    @media @mobile {
      margin-top: 1em;
      padding-right: 1em;
    }
  }

  &__header {
    @media @desktop {
      font-size: 18px;
      font-weight: bold;
      display: block;
      margin-bottom: .5em;
    }
    @media @mobile {
      font-size: 24px;
    }
  }

  &__description {
    @media @desktop {
      font-size: 28px;
    }
    @media @mobile {
      font-size: 24px;
    }
  }


  &__button {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: #8629df;
    background-color: #fff;
    cursor: pointer;
    .button();

    @media @desktop {
      display: none;
    }

    .team-member--expanded &,
    .team-member:hover & {
      @media @desktop {
        display: flex;
      }
    }

    @media @mobile {
      position: absolute;
      z-index: 1;
      right: 50px;
      top: 280px;
      display: flex;
    }
  }
}
